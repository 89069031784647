import { FC } from 'react'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import Button from '../../parts/button/button'

import styles from './get-nmo.module.scss'

interface IGetNmo {
  isMobile: boolean
  showRegistrationForm: () => void
}

const GetNmo: FC<IGetNmo> = ({ showRegistrationForm }) => {
  return (
    <section className={styles.sectionWrapper}>
      <WidthLimiter noTopPadding noBottomPadding>
        <div className={styles.title}>
          Набирайте <span className={styles.outlined}>баллы&nbsp;НМО</span> <br />с Medpoint
        </div>
        <h1 className={styles.subtitle}>
          Онлайн-обучение для врачей всех специальностей при&nbsp;поддержке РНИМУ им. Пирогова
        </h1>

        <div className={styles.btnWrap}>
          <Button onClick={showRegistrationForm}>Зарегистрироваться</Button>
        </div>
      </WidthLimiter>
    </section>
  )
}

export default GetNmo
